<template>
  <v-container>
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Widok rundy</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field outlined dense v-model="round.url" label="Adres URL" prepend-icon="mdi-web"
                      :rules="[rules]" placeholder="https://live.rubiart.pl/competitions/:competition/rounds/:round"/>
        <v-text-field outlined dense v-model="round.tool" readonly prepend-icon="mdi-open-in-new"/>
        <v-btn color="primary" @click="copy(round.tool)">
          <v-icon left>mdi-content-copy</v-icon>
          KOPIUJ ADRES URL DO SCHOWKA
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AdminOBS',
  data: () => ({
    key: 'HYYgzPKmjUs2n3pPcucdYvTabbaTC7RaGG377C428kS6dRK2by6LSPxFBxq4',
    round: {url: '', tool: ''}
  }),
  created() {

  },
  destroyed() {
    this.sse.close();
  },
  methods: {
    rules(url) {
      return /^https:\/\/live.rubiart.pl\/competitions\/[A-Za-z0-9_-]{1,120}\/rounds\/[A-Za-z0-9_-]{1,60}$/.test(url)
          || 'Podany adres URL jest niepoprawny';
    },
    async copy(string) {
      await navigator.clipboard.writeText(string);
      window.alert('Skopiowano adres URL do schowka');
    }
  },
  watch: {
    'round.url': function (url) {
      if (this.rules(url) === true) {
        const key = this.key;
        const competition = url.split('/competitions/')[1].split('/rounds/')[0];
        const round = url.split('/rounds/')[1];
        this.round.tool = window.location.origin + '/admin/obs/round?key=' + key + '&competition=' + competition + '&round=' + round;
      } else this.round.tool = '';
    }
  }
};
</script>
